<template src="./OvisViewer.html"></template>

<script>
import ovisService from "../../services/Ovis";

export default {
  name: "ovisViewer",
  data() {
    return {
      pages: [],
      theHTML: "",
      ArrayOvi: [],
      arrow: true,
      arrowBoth: false,
      arrowHover: false,
      indicator: false,
      oviId: "",
      infoOvi: { data: { dataTemplate: [] } },
    };
  },
  created() {
    this.oviId = this.$route.params.oviId;
    this.getInfoOvi();
  },
  methods: {
    async getInfoOvi() {
      try {
        this.infoOvi = await ovisService.getOvis(this.oviId);
        this.pages = this.infoOvi.data.dataTemplate.pages;
        this.showOvis();
      } catch (error) {
        console.error(error);
      }
    },
    showOvis() {
      for (let index = 0; index < this.pages.length; index++) {
        const element = this.pages[index];
        this.theHTML = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
      <title>Document</title>
      <style>${element.css}</style>
      </head>
      ${element.html}
      </html>`;
        this.ArrayOvi.push(this.theHTML);
      }
    },
    fullScreen() {
      const element = document.querySelector("#fullScreen");

      // make the element go to full-screen mode
      element
        .requestFullscreen()
        .then(function () {
          // element has entered fullscreen mode successfully
        })
        .catch(function (error) {
          console.log(error);
          // element could not enter fullscreen mode
        });
    },
  },
};
</script>

<style lang="scss" scoped src="./OvisViewer.scss"></style>