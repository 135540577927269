import { nodeInstance } from "@/plugins/axios";

const ovisService = {};

ovisService.getOvis = id => {
  return nodeInstance.get(`/api/ovis/${id}`);
};

ovisService.updateOvis = data => {
  console.log(data);
  return nodeInstance.patch(`/api/ovis/${data.id}`, data.body);
};

ovisService.createOvis = data => {
  return nodeInstance.post(`/api/ovis`, data);
};

ovisService.deleteOvis = id => {
  return nodeInstance.delete(`/api/ovis/${id}`);
};

export default ovisService;
